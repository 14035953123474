<template>
  <div class="system-settings">
    <b-tabs
      :value="tabIndex"
      nav-class="nav-left"
      pills
      vertical
      @input="handleTabChange"
    >
      <b-tab
        v-for="tab of permittedTabs"
        :key="tab.title"
        :active="$route.name === tab.routeName"
      >
        <template #title>
          <div class="d-flex align-items-center">
            <feather-icon
              :icon="tab.icon"
              size="16"
            />
            <span class="font-weight-bold text-truncate d-inline-block">
              {{ $t(tab.title) }}
            </span>
          </div>
        </template>
      </b-tab>
      <router-view />
    </b-tabs>
  </div>
</template>

<script>
import { systemSettingsTabs } from '@/navigation/vertical'

export default {
  data() {
    return {
      tabIndex: null,
      tabs: this.$lodash.cloneDeep(systemSettingsTabs),
      isLoaded: false,
    }
  },

  computed: {
    permittedTabs() {
      return this.tabs.filter(tab => this.hasPermission(tab.routeName))
    },
  },

  methods: {
    handleTabChange(index) {
      let { query } = this.$route

      const sidebarItem = this.permittedTabs[index]

      if (sidebarItem.routeName !== this.$route.name) {
        query = {}
      }

      const route = {
        name: sidebarItem.routeName,
        query,
      }

      if (sidebarItem.initialTab) {
        route.params = {
          tab: sidebarItem.initialTab,
        }
      }

      this.$router.push(route).catch(() => null)
    },

    hasPermission(routeName) {
      if (routeName === 'model') return false
      return this.$can('Read', 'System_Settings')
      // if (routeName === GenericTabs.COMPANY_SETTINGS) {
      //   return this.$can('Read', 'Settings')
      // }

      // if (routeName === GenericTabs.CRM_RELATIONSHIP_SETTINGS) {
      //   return this.$can('Read', 'CRM')
      // }

      // if (
      //   [
      //     GenericTabs.CUSTOM_FIELD_SETTINGS,
      //     GenericTabs.FIELD_MANAGEMENT,
      //   ].includes(routeName)
      // ) {
      //   return this.$can('Read', 'Field_Management')
      // }

      // if (routeName === GenericTabs.CUSTOM_ENTITY_SETTINGS) {
      //   return this.$can('Read', 'Custom_Entity')
      // }

      // if (routeName === GenericTabs.DASHBOARD_WIDGET_SETTINGS) {
      //   return this.$can('Read', 'Dashboard')
      // }

      // if (routeName === GenericTabs.EXPANDED_ENTITY_OVERVIEW) {
      //   return this.$can('Read', 'Expanded_Entity')
      // }

      // if (routeName === GenericTabs.SHORTCUTS_SETTINGS) {
      //   return this.$can('Read', 'System_Settings')
      // }

      // return this.$can('PerformAs', 'CompanyAdmin')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/system-settings.scss';
</style>
